@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 0.8;
    }
    100% {
        transform: scale(0.95);
        opacity: 0.5;
    }
}

.pulse-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #3793FF;
    animation: pulse 2s ease-in-out infinite;
}

.tooltip-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.tooltip-content div {
    color: #C6D3E2;
    font-size: 12px;
}

.tooltip-content div:last-child {
    color: #7A89A1;
    font-size: 11px;
    margin-top: 2px;
}

.tooltip {
    position: absolute;
    text-align: left;
    font-size: 12px;
    padding: 8px;
    background: #182338;
    border: 1px solid #334670;
    border-radius: 4px;
    pointer-events: none;
    color: #C6D3E2;
    z-index: 100;
    width: 'max-content' !important;
}

.visavail-tooltip div.tooltip-overlay {
    width: max-content !important;
}

.visavail-tooltip .tooltip-overlay {
    background: #182338 !important;
    border: 1px solid #334670 !important;
    border-radius: 4px !important;
    padding: 12px !important;
    font-size: 12px !important;
    color: #C6D3E2 !important;
    min-width: 200px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.visavail-tooltip .tooltip-overlay .font-medium {
    color: #EBF1F7 !important;
    margin-bottom: 4px !important;
}

.visavail-tooltip .tooltip-overlay .text-textdark {
    color: #7A89A1 !important;
}

.tooltip_has_data {
    color: #2DD881;
    margin-right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
}

.tooltip_has_no_data {
    color: #ef4444;
    margin-right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
}

.visavail-chart .rect_has_data {
    fill: #2DD881;
    opacity: 0.5;
}

.visavail-chart .rect_has_no_data {
    fill: #ef4444;
    opacity: 0.5;
}

.visavail-chart .rect_has_data:hover {
    opacity: 0.8;
}

.visavail-chart .rect_has_no_data:hover {
    opacity: 0.8;
}

.visavail-chart text {
    font-size: 12px;
    fill: #7A89A1;
}

.visavail-chart .hAxis path,
.visavail-chart .hAxis line {
    stroke: none;
}

.visavail-chart #hGrid line.horz_grid {
    stroke: none;
    stroke-width: 0;
}

.visavail-chart .domain {
    stroke: none;
} 