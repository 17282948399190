@import url('non.geist');
@import "inter-ui/inter.css";
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";
@import 'gridstack/dist/gridstack.min.css';
@import 'visavail/visavail.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    :root {
        --background: #151F32;
        --background-dark: #151F32;
        --background-medium: #182338;
        --background-light: #1D273F;

        --text-dark: #7A89A1;
        --text-medium: #C6D3E2;
        --text-light: #EBF1F7;

        --primary: #3793FF;
        --primary-transparent: rgba(55, 147, 255, 0.1);
        --primary-hover: #38A4FF;
        --primary-foreground: #3793FF;

        --secondary: #2DD881;
        --secondary-transparent: rgba(45, 216, 129, 0.6);
        --secondary-transparenter: rgba(45, 216, 129, 0.1);

        --red-transparenter: rgba(255, 0, 0, 0.1);

        --button: #334670;
        --button-hover: #202C46;
        --button-border: #334670;

        --border: #334670;
        --input: #182338;
        --ring: #3793FF;

        --radius: 0.125rem;

        --sidebar-background: 0 0% 98%;

        --sidebar-foreground: 240 5.3% 26.1%;

        --sidebar-primary: 240 5.9% 10%;

        --sidebar-primary-foreground: 0 0% 98%;

        --sidebar-accent: 240 4.8% 95.9%;

        --sidebar-accent-foreground: 240 5.9% 10%;

        --sidebar-border: 220 13% 91%;

        --sidebar-ring: 217.2 91.2% 59.8%;
    }
  .dark {
        --sidebar-background: 240 5.9% 10%;
        --sidebar-foreground: 240 4.8% 95.9%;
        --sidebar-primary: 224.3 76.3% 48%;
        --sidebar-primary-foreground: 0 0% 100%;
        --sidebar-accent: 240 3.7% 15.9%;
        --sidebar-accent-foreground: 240 4.8% 95.9%;
        --sidebar-border: 240 3.7% 15.9%;
        --sidebar-ring: 217.2 91.2% 59.8%;
    }
}

@layer utilities {
    .popover-content-width-same-as-its-trigger {
        max-height: var(--radix-popover-content-available-height);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .markdown > * {
        all: revert;
    }
}


@layer base {
    * {
        @apply border-border;
    }

    ::selection {
        background: var(--secondary);
        color: var(--text-light);
    }

    .highlight {
        color: var(--text-light);
        background: var(--secondary-transparent);
    }


    body {
        @apply bg-background text-foreground;
        text-rendering: optimizeLegibility;
        font-family: 'Inter';
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background-color:  rgb(51, 70, 112);
        }

        scrollbar-width: thin;
        scrollbar-color: rgb(51, 70, 112) rgb(19, 26, 42);
    }

    .scrollMedium {
        scrollbar-width: thin;
        scrollbar-color: rgb(51, 70, 112) #182338;
    }

    div[hidden] {
        display: none;
    }

    div[data-vaul-no-drag] {
        user-select: text;
    }

    /* for having divs with placeholders */
    [contentEditable=true]:empty:not(:focus):before {
        content: attr(title);
        color: var(--text-dark);
        align-self: center;
        font-size: small;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

